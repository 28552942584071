<template>
    <div v-if="missingSruAccountsExists" class="message-block">
        <div class="flex flex-row justify-between">
            <p class="font-bold">Dessa konton ingår inte i {{ financialPage }}, vänligen lägg till SRU-kod i kontoplan och uppdatera {{ financialPage }} innan du påbörjar några ändringar</p>
            <el-button @click="isResetModalVisible = true" type="default" plain class="p-1 ml-3">
                <i class="fa-solid fa-arrows-rotate cursor-pointer" :title="`Uppdatera ${financialPage}`" />
            </el-button>
            <el-dialog :visible.sync="isResetModalVisible" width="25%" :title="`Uppdatera ${financialPage}`">
                <div class="flex flex-col space-y-2">
                    <h1 class="font-bold">Är du säker att du vill Uppdatera {{ financialPage }}?</h1>
                    <div class="flex flex-row justify-end">
                        <el-button @click="resetAccount" type="success">Uppdatera</el-button>
                        <el-button @click="isResetModalVisible = false" type="warning">Avbryt</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div v-for="account in missingSruAccounts" :key="account.account" class="flex space-x-2">
            <p>{{ account.account }}</p>
            <p>{{ account.name }}</p>
        </div>
    </div>
</template>

<script>
import Api from "../../annualReport.api";

export default {
    props: {
        financialData: {
            type: Object,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        annualReportId: {
            type: String,
        },
        financialPage: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isResetModalVisible: false,
        };
    },
    methods: {
        async resetAccount() {
            this.isResetModalVisible = false;
            this.$waiting.start("loading");
            if (this.financialPage === "resultaträkningen") {
                const newData = await Api.createIncomeStatement(this.annualReportId, this.clientId, this.clientInfo.financialYearStart, this.clientInfo.financialYearEnd, true);
                this.$emit("create-income-statement-data", newData);
                this.$emit("update-income-statement-details");
            } else if (this.financialPage === "balansräkningen") {
                const newData = await Api.createBalanceSheet(this.annualReportId, this.clientId, this.clientInfo.financialYearStart, this.clientInfo.financialYearEnd, true);
                this.$emit("create-balance-sheet-data", newData);
                this.$emit("update-balance-sheet-details");
            }
            this.$waiting.end("loading");
            this.$emit("update-is-displaying-accounts", false);
            this.$notify.success({ title: `${this.financialPage.charAt(0).toUpperCase() + this.financialPage.slice(1)} uppdaterad`, duration: 15000 });
        },
    },
    computed: {
        missingSruAccounts() {
            if (this.financialData && this.financialData.row) {
                return this.financialData.row.filter(row => row.sruCode === "");
            }
            return [];
        },
        missingSruAccountsExists() {
            return this.missingSruAccounts.length !== 0;
        },
    },
};
</script>

<style scoped>
.message-block {
    border-left-width: 4px;
    border-color: #f04e4e;
    background-color: #f8e5e5;
    padding: 20px;
    margin: 20px 25px 0 25px;
    border-radius: 10px;
}
</style>
